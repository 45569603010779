
import { computed, defineComponent, onMounted, reactive, ref, Ref, watch } from 'vue';
import _reverse from 'lodash/reverse';
import _uniq from 'lodash/uniq';
import _orderBy from 'lodash/orderBy';

import Layout from '@/components/Layout.vue';
import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import MeetingOverlay from '@/components/meeting/Overlay.vue';
import AddMeetingOverlay from '@/components/meeting/AddOverlay.vue';

import Store from '@/store';
import Meeting from '@/models/meeting.model';
import useEmitter from '@/compostables/useEmitter';
import MeetingService from '@/services/meeting.service';

export default defineComponent({
	components: {
		Layout,
		SVG,
		Loader,
		MeetingOverlay,
		AddMeetingOverlay,
	},

	setup() {
		const emitter = useEmitter();

		const user = Store.get('user');
		const meetings = Store.get('meetings');
		const meetingsToShow: Ref<Meeting[]> = ref([]);
		const loading = ref(true);
		const years: Ref<number[]> = ref([]);
		const activeYear = ref(0);

		const overlay = reactive({
			active: false,
			addActive: false,
		});

		const quarterlyMeetings = computed(() => {
			const quarterly: Meeting[][] = [[], [], [], []];

			for (const meeting of meetingsToShow.value) {
				let date = new Date(meeting.scheduled_at);

				if (typeof meeting.scheduled_at === 'string') {
					// toString and replace is because of Safari
					date = new Date(meeting.scheduled_at?.toString()?.replace(' ', 'T') + '.0000Z');
				}

				if (!date) {
					return;
				}

				const month = date.getMonth() / 3;

				quarterly[Math.floor(month)].push(meeting);

				quarterly[Math.floor(month)] = _orderBy(quarterly[Math.floor(month)], ['scheduled_at'], ['desc']);
			}

			return quarterly;
		});

		watch(meetings, (meetingsValue) => {
			meetingsToShow.value = meetingsValue.filter((meeting: Meeting) => {
				if (meeting.scheduled_at instanceof Date) {
					return activeYear.value === meeting.scheduled_at.getFullYear();
				} else {
					// toString and replace is because of Safari
					return (
						activeYear.value ===
						new Date(meeting?.scheduled_at?.toString()?.replace(' ', 'T') + '.0000Z').getFullYear()
					);
				}
			});
		});

		// Carousel
		const position = ref(0);
		const singleItem = ref(56);
		const fullLength = ref(0);

		onMounted(async () => {
			emitter.on('escape', () => {
				overlay.active = false;
				overlay.addActive = false;
			});

			// Get the Meetings
			if (!meetings.value) {
				await MeetingService.get().then((response) => {
					if (response) {
						Store.set('meetings', response);
					}
				});
			}

			Store.set('header.nav', [
				{
					title: 'Listi',
					name: 'Meetings',
				},
				{
					title: 'Ársyvirlit',
					name: 'MeetingCards',
				},
			]);

			Store.set('header.options', [
				{
					title: 'Stovna fund',
					action: 'task:add',
				},
			]);

			// Open Task overlay
			emitter.on('task:add', () => {
				Store.set('meeting', null);
				overlay.addActive = true;

				const input: HTMLInputElement | null = document.querySelector('.meeting-input');

				if (input) {
					input.focus();
				}
			});

			const getYears: number[] = [];
			meetings.value.map((meeting: Meeting) => {
				if (meeting?.scheduled_at) {
					if (meeting.scheduled_at instanceof Date) {
						getYears.push(meeting.scheduled_at.getFullYear());
					} else {
						// toString and replace is because of Safari
						getYears.push(
							new Date(meeting?.scheduled_at?.toString()?.replace(' ', 'T') + '.0000Z').getFullYear()
						);
					}
				}
			});

			years.value = _reverse(_uniq(getYears).sort());

			fullLength.value = (years.value.length * singleItem.value) / 2;

			chooseYear(years.value[0]);

			loading.value = false;
		});

		// Open the Meeting Overlay

		function openOverlay(meetingId: number) {
			Store.set(
				'meeting',
				meetings.value.find((meeting: Meeting) => meeting.id === meetingId)
			);

			overlay.active = true;
		}

		// Carousel functions
		function chooseYear(year: number) {
			activeYear.value = year;

			meetingsToShow.value = meetings.value.filter((meeting: Meeting) => {
				if (meeting.scheduled_at instanceof Date) {
					return activeYear.value === meeting.scheduled_at.getFullYear();
				} else {
					// toString and replace is because of Safari
					return (
						activeYear.value ===
						new Date(meeting?.scheduled_at?.toString()?.replace(' ', 'T') + '.0000Z').getFullYear()
					);
				}
			});
		}

		function prev() {
			if (position.value === 0) {
				return;
			}

			position.value = position.value - singleItem.value;

			const container = document.querySelector<HTMLElement>('.years-container');

			if (container) {
				container.style.transform = `translateX(-${position.value}px)`;
			}
		}

		function next() {
			if (position.value === fullLength.value) {
				return;
			}

			position.value = position.value + singleItem.value;

			const container = document.querySelector<HTMLElement>('.years-container');

			if (container) {
				container.style.transform = `translateX(-${position.value}px)`;
			}
		}

		return {
			// data
			user,
			loading,
			years,
			activeYear,
			position,
			fullLength,
			quarterlyMeetings,
			overlay,

			// functions
			openOverlay,
			chooseYear,
			prev,
			next,
		};
	},
});
