import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center pt-12"
}
const _hoisted_2 = {
  key: 1,
  class: "fixed z-1 w-full h-11 bg-white-500 px-12 flex items-center"
}
const _hoisted_3 = { class: "relative flex items-center h-full w-56 overflow-x-hidden" }
const _hoisted_4 = { class: "years-container flex items-center transition" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 2,
  class: "flex space-x-4 items-start p-12 pt-[92px]"
}
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = { class: "flex flex-col space-y-[2px] w-[384px]" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "text-gray-400 text-xs font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_SVG = _resolveComponent("SVG")!
  const _component_MeetingOverlay = _resolveComponent("MeetingOverlay")!
  const _component_AddMeetingOverlay = _resolveComponent("AddMeetingOverlay")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { noPadding: true }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Loader, { class: "w-8 h-8 text-blue" })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.years.length > 4)
              ? (_openBlock(), _createBlock(_component_SVG, {
                  key: 0,
                  name: "icons/chevron",
                  onClick: _ctx.prev,
                  class: _normalizeClass(["cursor-pointer w-1.5 h-3.5 transform rotate-180 mr-1", { 'opacity-50': _ctx.position === 0 }])
                }, null, 8, ["onClick", "class"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (year) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: year,
                    onClick: () => _ctx.chooseYear(year),
                    class: _normalizeClass(["text-sm w-14 flex cursor-pointer justify-center", { 'font-bold': _ctx.activeYear === year }])
                  }, _toDisplayString(year), 11, _hoisted_5))
                }), 128))
              ])
            ]),
            (_ctx.years.length > 4)
              ? (_openBlock(), _createBlock(_component_SVG, {
                  key: 1,
                  name: "icons/chevron",
                  onClick: _ctx.next,
                  class: _normalizeClass(["cursor-pointer w-1.5 h-3.5 ml-1", { 'opacity-50': _ctx.position === _ctx.fullLength }])
                }, null, 8, ["onClick", "class"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
              return _createElementVNode("div", {
                key: i,
                class: "p-4 bg-white rounded-lg shadow-lg"
              }, [
                _createElementVNode("h3", _hoisted_7, "Q" + _toDisplayString(i), 1),
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quarterlyMeetings[i - 1], (meeting) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: meeting.id,
                      onClick: () => _ctx.openOverlay(meeting.id),
                      class: "px-4 py-2.5 text-gray bg-[#f9fafb] hover:bg-[#f6f7f9] text-sm font-bold rounded-lg transition cursor-pointer max-w-full"
                    }, [
                      _createElementVNode("div", null, _toDisplayString(meeting.title), 1),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(new Date(meeting?.scheduled_at).format('%-d/%-m/%y2')), 1)
                    ], 8, _hoisted_9))
                  }), 128))
                ])
              ])
            }), 64))
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.can('update meetings'))
        ? (_openBlock(), _createBlock(_component_MeetingOverlay, {
            key: 3,
            active: _ctx.overlay.active,
            "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.overlay.active) = $event))
          }, null, 8, ["active"]))
        : _createCommentVNode("", true),
      (_ctx.user.can('create meetings'))
        ? (_openBlock(), _createBlock(_component_AddMeetingOverlay, {
            key: 4,
            active: _ctx.overlay.addActive,
            "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.overlay.addActive) = $event))
          }, null, 8, ["active"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}